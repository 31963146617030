@font-face {
  font-family: 'Bookman';
  src: local('Bookman'),url('./Font/bookman-old-style.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-light';
  src: local('Roboto-light'),url('./Font/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans-Light';
  src: local('NotoSans-Light'),url('./Font/NotoSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'MonumentExtended';
  src: local('MonumentExtended'),url('./Font/MonumentExtended-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'arkit-light';
  src: local('arkit-light'),url('./Font/arkit-light.ttf') format('truetype');
}

*{
  
  margin: 0;
  padding: 0;
  
  text-decoration: none !important;
}
  body {
  font-family:'arkit-light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

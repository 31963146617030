@font-face {
  font-family: 'Bookman';
  src: local('Bookman'),url(/static/media/bookman-old-style.4267d8aa.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-light';
  src: local('Roboto-light'),url(/static/media/Roboto-Light.881e150a.ttf) format('truetype');
}
@font-face {
  font-family: 'NotoSans-Light';
  src: local('NotoSans-Light'),url(/static/media/NotoSans-Light.1853a578.ttf) format('truetype');
}
@font-face {
  font-family: 'MonumentExtended';
  src: local('MonumentExtended'),url(/static/media/MonumentExtended-Light.15a0b21f.ttf) format('truetype');
}
@font-face {
  font-family: 'arkit-light';
  src: local('arkit-light'),url(/static/media/arkit-light.4cc84e3f.ttf) format('truetype');
}

*{
  
  margin: 0;
  padding: 0;
  
  text-decoration: none !important;
}
  body {
  font-family:'arkit-light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

